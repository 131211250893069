.navbar-profile {
    .page-title {font-size: 1.85em}

    .navbar-left-items {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 50%;
        padding: 0;

        img {width: 120px}
    }

    .navbar-profile-actions {
        display: inline-block;
        vertical-align: top;
        text-align: right;
        width: 50%;
    }

    
    .header-item {
        height: $header-height;
        box-shadow: none !important;
        color: $header-item-color;
        border: 0;
        border-radius: 0px;
        text-align: right;
        width: 100%;

        @media screen and (max-width: 900px) {
            width: 80px;
        }

        &:hover {
            color: $header-item-color;
        }

        .header-profile-user {
            background-color: $gray-300;
            display: inline-block;
            height: 28px;
            width: 28px;
            padding: 3px;
            margin-right: 8px;
        }
    
        .header-item-profile-name {
            display: inline-block;
            vertical-align: top;
            padding-top: 6px;
            margin-right: 8px;
            font-size: 12px;

            @media screen and (max-width: 900px) {
                display: none;
            }
        }

        .header-item-chevron {
            display: inline-block;
            vertical-align: top;
            padding-top: 6px;
        }
    }
}