.side-modal {
    position: absolute;
    top: 0; right: 0; height: 100%;
    width: 40%; min-height: 100%;
    background: #F9F9F9; z-index: 2048;
    overflow-y: scroll;
    border-radius: 0;

    -moz-box-shadow:0 0 15px #a5a5a5;
    -webkit-box-shadow:0 0 15px #a5a5a5;
    box-shadow:0 0 15px #a5a5a5;

    -webkit-animation: movingbox2 150ms;
    -o-animation: movingbox2 150ms;
    animation: movingbox2 150ms;
    animation-name: movingbox2;
    animation-duration: 150ms;

    &.xxl {width: 70%}
    &.xl {width: 60%}
    &.md {width: 50%}
}

.side-modal .modal-body {min-height: 100%; height: 100%; max-height: 100%;}
.in {display: inline;}
.side-modal .modal-header .close {position: absolute;top: 0; right: 0;}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
    .side-modal {top: 0px; right: 0; width: 100%; min-height: 800px;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
    .side-modal {top: 0; right: 0; width: 80%; min-height: 1000px;}
    .modal-body {max-height: 100%;}
}

@keyframes movingbox {
    0%{width:100%;}
    50%{width:80%;}
    100%{width:45%;}
}

@-webkit-keyframes movingbox2 {
    0%{width:0;}
    50%{width:20%;}
    100%{width:40%;}
}

@keyframes movingbox2 {
    0%{width:0;}
    50%{width:20%;}
    100%{width:40%;}
}