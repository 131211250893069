.content {
    display: inline-block;
    vertical-align: top;
    margin-left: 15vw;
    margin-top: 24px;
    width: 83.5vw;

    &.open {
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1024px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            margin-left: 30vw;
        }

        @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 480px)
        and (-webkit-min-device-pixel-ratio: 2) {
            margin-left: 60vw;
        }
    }

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        padding: 1vw;
        margin-left: 0;
        width: 100vw;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
        padding: 1vw;
        margin-left: 0;
        width: 100vw;
    }

    .hideOnMobile {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    table {
        @media screen and (max-width: 600px) {
            max-width: 95%;
            width: 95%;
        }
    }

    h2 {
        font-size: 2.65em;
        font-weight: 700;
        color: #5c6876;
    }

    h3 {
        letter-spacing: .005em;
        font-size: 1.65em;
        font-weight: 400;
        color: #5c6876;
    }

    .paper-heading {
        padding: 8px 24px;
        border-bottom: 1px solid #dcdcdc;
    }

    .configuration-container {
        .configuration-nav {
            padding: 16px 0;

            ul {
                list-style: none;
                padding: 0; margin: 0;

                li {
                    a {
                        display: block;
                        padding: 12px 8px;
                        color: #4d4d4d;

                        &:hover {
                            text-decoration: none;
                            background: #eeeef3;
                        }
                    }
                }
            }
        }
    }

    .status {
        display: block;
        padding-top: 8px;
        text-transform: capitalize;

        &.pagada {color: green}
        &.nopagada {color: red}
    }
}

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .page-title {
        @media only screen 
        and (max-device-width: 1024px) {
            font-size: 16px;
        }

        i {
            margin-right: 16px;
        }
    }

    .breadcrumb {
        background-color: transparent;
        padding: 0;

        @media only screen 
        and (max-device-width: 1024px) {
            font-size: 10px;
        }
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px !important;
    }
}

.responsive {max-width: 100%;}

.unidades_list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        text-transform: capitalize;
        padding: 8px 0;

        button {
            margin-right: 8px;
        }
    }
}

.ac_results {
    border: 1px solid #c7c7c7;
    background-color: white;
    overflow: hidden;
    z-index: 99999;
    padding: 0px;

    ul {
        width: 100%;
        list-style-position: outside;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0px;
            padding: 4px 8px;
            cursor: default;
            display: block;
            line-height: 1.5;
            overflow: hidden;
        }
    }
}

// .graph-wrapper {
//     padding: 0;

//     .panel-body {padding: 0}

//     .graph {
//         display: inline-block;
//         width: 70%;

//         @media screen and (max-width: 600px) {
//             display: block;
//             width: auto;
//         }

//         .react-d3-core__legend {
//             width: 100% !important;
//         }

//         .react-d3-core__container_svg {
//             height: 250px;
//             width: 100% !important;

//             @media screen and (max-width: 600px) {
//                 max-width: 95vw;
//             }
//         }
//     }

//     .numbers {
//         display: inline-block;
//         vertical-align: top;
//         width: 30%;

//         @media screen and (max-width: 600px) {
//             display: block;
//             width: auto;
//         }

//         .totals {
//             padding: 10vh 0 0 0;
//             text-align: center;

//             span {
//                 font-size: 2.34em;
//                 font-weight: bold;
//                 color: #2e939c;
//             }

//             strong {
//                 font-weight: normal;
//                 font-size: 1.2em
//             }
//         }
//     }
// }