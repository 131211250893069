.full-page-loader {
    float: left;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invoice-pagada{
    min-width: 80px;
    padding: 0 10px !important;
    height: 25px;
    margin-right: 24px;
    line-height: 25px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    color: #67d2ab;
    text-align: center;
    background-color: #daf4eb;
}

.invoice-porcobrar {
    min-width: 80px;
    padding: 0 10px !important;
    height: 25px;
    margin-right: 24px;
    line-height: 25px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    color: #f79494;
    text-align: center;
    background-color: #fee4e5;
}

.graph-totals {
    padding: 10vh 0 0 0;
    text-align: center;

    span {
        font-size: 1.85em;
        font-weight: bold;
        color: #71C9DA;
    }

    strong {
        font-weight: normal;
        font-size: 1.2em
    }
}

.invoice-services-option {
    background: #f6f6f6;
    padding: 8px;
}

.login {
    .logo {padding: 24px 0}

    h1 {
        font-size: 28px !important;
        padding: 24px 0 16px 0;
        line-break: 200px;
    }

    h2 {
        font-size: 18px;
    }

    .login-signup {text-align: center}

    .login-copyright {
        padding: 32px 0;
        color: #9a9a9b;
        text-align: center;
    }
}

.signup {
    .wrapper {
        width: 650px;

        @media screen and (max-width: 800px) {
            width: auto;
        }
    }

    .form-actions {
        button {width: 100%;}
    }

    .text-align-left {text-align: left;}
}

.required-error {border: 1px solid red}

.draftjs {min-height: 250px}

.card-title {
    font-size: 18px;
    padding: 16px 0;
}

.form {
    .form-group {
        margin: 16px 0;
    
        .form-control, select {
            padding: 8px 12px !important;
            font-size: 14px !important;
            font-weight: 200 !important;
            height: 52px !important;
        }
    }
}

.form-group {
    margin: 16px 0;

    .form-control, select {
        padding: 8px 12px !important;
        font-size: 14px !important;
        font-weight: 200 !important;
        height: 38px !important;
    }
}

.product {
    &:hover {
        background: #f1f1f1;
    }

    a {cursor: pointer}

    .product-name {font-weight: bold}
}

.cart-item {
    font-size: 90%;
    padding: 16px 0;
    border-bottom: 1px solid #f1f1f1;

    .cart-item-quantity {
        padding-top: 6px;
        vertical-align: middle;
        text-align: center;
    }
}

.table-borderless > tbody > tr > td {border: none}

.cart-options {margin-bottom: 24px}

.breadcrumb {
    margin-top: 24px;
    font-size: 12px;
}

.flex-fill {
    a {
        color: #bababa;

        &:hover {color: #ff5a5f}
    }
}

.configuration-nav {
    nav {
        a {
            margin: 0 16px;
            color: #495057;

            &:hover, &.active {color: #24bba5}
        }
    }
}

.branches-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        margin: 12px 0;

        button {
            border: 1px solid transparent;
            margin-right: 12px;
        }
    }
}

.settings_company {
    .settings_company_options {
        a {color: #424242}
    }
}

.form-actions {text-align: right}

.purchase-order-status-label-ACTIVO {
    text-align: right;
    color: #20E23B;
    background: none;
}

.purchase-order-status-label-BORRADOR {
    text-align: right;
    color: #FFA300;
    background: none;
}

.purchase-order-status-label-CANCELADA {
    text-align: right;
    color: #F4382F;
    background: none;
}

.purchase-order-status-label-QUOTE_TURNED_ORDER {
    text-align: right;
    color: #2660EF;
    background: none;
}

.branch-contact-details {
    list-style: none;
    padding: 0;
    margin: 0;
}

.search-icon {
    left: 8px !important; 
}