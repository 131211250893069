.sidebar {
	background: white;
    text-align: left;
    position: fixed;
    z-index: 500;
    border: none;
	margin: 0;
    
    min-height: 110vh;
	height: 100vh;
	height: -webkit-calc(100% - 66px);
	height:         calc(100% - 66px);
	width: 15vw;

    // iPads (portrait and landscape)
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        display: none;
        position: fixed;
        top: 0; left: 0;
        width: 30vw;
    }

    // Samsung Tablets (portrait and landscape)
    @media
    (min-device-width: 800px) 
    and (max-device-width: 1280px) {
        display: none;
        position: fixed;
        top: 0; left: 0;
        width: 30vw;
    }

    // iPhones (portrait and landscape)
    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px)
    and (-webkit-min-device-pixel-ratio: 2) {
        display: none;
        position: fixed;
        top: 0; left: 0;
        width: 60vw;
    }

    &.open {
        @media only screen 
        and (min-device-width: 768px) 
        and (max-device-width: 1024px) 
        and (-webkit-min-device-pixel-ratio: 2) {
            display: block;
        }

        @media only screen  
        and (max-device-width: 1024px) {
            display: block;
        }
    }

	.site-menubar {
        .mm-active {
            >.has-arrow {
                &:after { transform: rotate(-180deg) }
            }
        }
    
        .has-arrow {
            &:after {
                content: "\F0140";
                font-family: 'Material Design Icons';
                display: block;
                float: right;
                transition: transform .2s;
                font-size: 2rem;
                color: #495057;
            }
        }

		.site-menubar-header {
            padding: 24px;
            text-align: center;

			img {max-width: 80%}
		}

		.site-menubar-body {
			height: 100%;
			height: -webkit-calc(100% - 76px);
            height:         calc(100% - 76px);

			.site-menu {
				padding: 0 0 24px 0;
				overflow-y: hidden;
				list-style: none;
				margin: 0;

				.site-menu-item {
					position: relative;
                    white-space: nowrap;
                    padding: 8px 24px;
                    display: block;
                    width: 100%;

                    &:hover {
                        .site-menu-icon, .site-menu-title {color: #1985a1}

                        a:hover {color: #000}
                    }

                    .site-menu-icon {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 1.5em;
                        width: auto;
                        color: #495057;
                        margin-right: 8px;
                    }

                    .site-menu-title {
                        vertical-align: middle;
                        display: inline-block;
                        width: auto;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #495057;
                    }

					a {
						// text-transform: uppercase;
						white-space: nowrap;
						cursor: pointer;
						font-weight: 500;
						display: block;
						color: #495057;

                        padding: .625rem 1.5rem;
                        position: relative;
                        font-size: 13px;
                        transition: all .4s;

						&:hover {text-decoration: none; color: #000;}
					}

                    .site-menu-submenu {
                        // display: none;
                        text-align: left;
                        padding: 8px 0 0 10px;
                        list-style: none;

                        li {
                            a {
                                padding: 6px 0;
                                color: #495057;
                                font-weight: 200;
                            }
                        }
                    }
				}
			}
		}
	}
}

.metismenu {
    margin: 0;

    li {
        display: block;
        width: 100%;
    }

    .mm-collapse {
        display: none;

        &:not(.mm-show) {display: none}

        &.mm-show {display: block}
    }

    .mm-active {color: #FFF}

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }

    .mm-separator {
        border-bottom: 1px solid #fbfbfb;
        margin-bottom: 6px;
        margin-top: 6px;
        opacity: 0.25;
        display: block;
        width: 90%;
    }
}